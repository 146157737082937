import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

const AddSubmissionNote = ({
  handleSaveNoteEdit,
  getnotes,
  handleEditNotes,
  handleNoteDelete,
  handleEditNoteChange,
  editedNoteIndex,
  handleCancelEdit,
  noteSubmitLoading,
}) => {
  return (
    <>
      <Box id="notes_list">
        {getnotes && getnotes?.length == 0 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={NotFound} alt="NotFound" style={{ width: "16rem" }} />
              <p>No Records Found! Please try again....</p>
            </div>
          </>
        ) : (
          <>
            <Box style={{ maxHeight: "400px", overflow: "auto" }}>
              {getnotes &&
                getnotes?.map((val, index) => (
                  <>
                    <Box className="nt_box">
                      <Box className="note_box">
                        <Box className="note_b">
                          <Box className="note_icon_box">
                            <EventNoteIcon style={{ color: "#718096" }} />
                          </Box>
                          <Text
                            style={{ fontWeight: "bold", color: "#718096" }}
                          >
                            Notes
                          </Text>
                        </Box>
                        <Box>
                          <Menu>
                            <MenuButton>
                              <MoreVertIcon
                                style={{
                                  color: "#718096",
                                  cursor: "pointer",
                                }}
                              />
                            </MenuButton>
                            <MenuList
                              style={{
                                marginLeft: "-5rem",
                                fontSize: "15px",
                                width: "fit-content",
                                minWidth: "auto",
                              }}
                            >
                              <MenuItem
                                icon={
                                  <BorderColorIcon
                                    style={{
                                      color: "#718096",
                                      fontSize: "17px",
                                    }}
                                  />
                                }
                                onClick={() => handleEditNotes(index)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                icon={
                                  <DeleteIcon
                                    style={{
                                      color: "#718096",
                                      fontSize: "17px",
                                    }}
                                  />
                                }
                                onClick={() => {
                                  handleNoteDelete(val?._id);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </Box>
                      <Text className="notes_detail">
                        {editedNoteIndex === index ? (
                          <>
                            <textarea
                              rows="4"
                              cols="50"
                              value={val?.candidateNote}
                              onChange={(e) =>
                                handleEditNoteChange(e.target.value, index)
                              }
                              style={{ padding: "1rem" }}
                            />
                            <Box
                              style={{
                                display: "flex",
                                gap: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <Button
                                className="cancel"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="addnote_btn"
                                onClick={() => {
                                  handleSaveNoteEdit(val?._id);
                                }}
                                isDisabled={noteSubmitLoading}
                                isLoading={noteSubmitLoading}
                              >
                                Save
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <>
                            {val?.candidateNote
                              .split("\n")
                              .map((line, index) => (
                                <Text key={index}>
                                  {line}
                                  <br />
                                </Text>
                              ))}
                          </>
                        )}
                      </Text>
                      <Text className="notes_detail">{`${
                        val?.noteSubmitter
                      } on ${moment(
                        val?.updated_at ? val?.updated_at : val?.created_at
                      ).format("DD/MM/YYYY")} at ${moment(
                        val?.updated_at ? val?.updated_at : val?.created_at
                      ).format("h:mm A")}`}</Text>
                    </Box>
                  </>
                ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AddSubmissionNote;
