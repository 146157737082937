import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import DescriptionIcon from "@mui/icons-material/Description";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GroupIcon from "@mui/icons-material/Group";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import NotFound from "assets/svg/not-found.svg";
import axios from "axios";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { lazy, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useCookies } from "react-cookie";
import { RiUserForbidFill } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckPicker from "rsuite/CheckPicker";
import "rsuite/CheckPicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import config from "../../config";
import {
  ColumnChartsObject,
  FunnelChartOptions,
  ownershipChatOption,
} from "../../constant";
import Loadar from "./Loader";
import "./org.css";
import { useSelector } from "react-redux";

const TopSubmissionUsers = lazy(
  () => import("../../components/Dashboard/TopSubmissionUsers")
);
const MemberRecordsTable = lazy(
  () => import("../../components/Dashboard/MemberRecordsTable")
);
const CountCard = lazy(() => import("../../components/Dashboard/CountCard"));
const Card = lazy(() => import("../../components/Card/Card"));

export default function Dashboard() {
  const [cookie] = useCookies(["token", "role", "id"]);
  const userId = cookie?.id;
  const { userNames } = useSelector((state) => state.usersSlice);
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const history = useHistory();
  const [tooltipStatus, setTooltipStatus] = useState(null);

  const currentDate = new Date();
  const defaultStartDate = startOfMonth(currentDate);
  const defaultEndDate = endOfMonth(currentDate);

  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [jobposts, setJobposts] = useState([]);
  
  const [candidates, setCandidates] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [sourceDataKeys, setSourceDataKeys] = useState([]);
  const [sourceDataValues, setSourceDataValues] = useState([]);
  const [searchOwnerName, setSearchOwnerName] = useState([]);
  const [searchClientName, setSearchClientName] = useState([]);
  const [selectDashboard, setSelectDashboard] = useState({
    value: "userdashboard",
    label: "User Dashboard",
  });
  const [selectTeamName, setSelectTeamName] = useState({
    value: "",
    label: "",
  });
  const [firstItemOfTeam, setFirstItemOfTeam] = useState(null);
  const [firstItemOfClient, setFirstItemOfClient] = useState(null);
  const [alluserTeams, setAlluserTeams] = useState([]);
  const [teamMemberDatas, setTeamMemberDatas] = useState([]);
  const [topSubmissionUsersData, setTopSubmissionUsersData] = useState([]);
  const [selectedTeamData, setSelectedTeamData] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [filteredData,setFilteredData]=useState([]) 
  const [searchStartDate, setSearchStartDate] = useState(
    format(defaultStartDate, "dd/MM/yyyy")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    format(defaultEndDate, "dd/MM/yyyy")
  );

  const candidate_ownerName = localStorage.getItem("candidate_ownerName");

  const page = 0;
  const rowsPerPage = 5;

  const getJobpostdata = async (getpage, rowperpage, ownerName) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_all_search_jobpost?page=${getpage + 1}&limit=${rowperpage}&job_ownerName=${ownerName?.length == 0 ? ownerName : cookie?.role == "Admin" ? searchOwnerName || "" : userId || ""}&startDate=${defaultStartDate}&endDate=${defaultEndDate}`
      );
      const jobpostData = response.data.data;
      const totalJobPosts = response.data.totalJobPosts;
      jobpostData.totalJobPosts = totalJobPosts;
      setJobposts(jobpostData);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.message ||
          "Something happened wrong, please try again after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
    }
  };

  const getCandidatedata = async (getpage, rowperpage, ownerName) => {
    setCandidateLoading(true);
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_all_search_candidate?page=${getpage + 1}&limit=${rowperpage}&candidate_ownerName=${ownerName?.length == 0 ? ownerName : cookie?.role == "Admin" ? searchOwnerName || "" : userId?.trim() || ""}`,
        {
          headers: {
            Authorization: `Bearer ${cookie.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const candidateData = response.data.data;
      setCandidates(candidateData);
      setCandidateLoading(false);
    } catch (error) {
      toast.error(
        error?.message ||
          "Something happened wrong, please try again after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setCandidateLoading(false);
    }
  };

  const getUniversalCount = async (
    startDate,
    endDate,
    ownerName,
    teamId,
    clientId
  ) => {
    setCountLoading(true);
   
    try {
      const response = await axios.get(
        `${config?.apiUrl}/get_universal_count?startDate=${startDate ? startDate : searchStartDate}&endDate=${endDate ? endDate : searchEndDate}&ownerName=${ownerName ? ownerName :  []}&teamId=${teamId ? teamId : ""}&clientId=${clientId ?? ""}`
      );
      const countsData = response.data.data;
      setSourceDataKeys(
        Object.keys(countsData?.sourceCountData).map((key) => key)
      );
      setTooltipStatus(response.data.status);
      setTeamMemberDatas(response.data.memberRecords);
      setSelectedTeamData(response.data.teamData);
      setTopSubmissionUsersData(response.data?.topSubmissionUsers);
      setSourceDataValues(Object.values(countsData?.sourceCountData));
      setTotalCounts(countsData);
      setCountLoading(false);
    } catch (err) {
      console.log("err::::::::::", err);
      toast.error(err?.response?.data?.msg || "Please check details", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCountLoading(false);
    }
  };

  const getAllUserTeams = async () => {
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_All_userTeams`
      );
      const statusdata = response.data.data;
   
      const filteredData = getUniqueUsers(filterPortalUsersByTeam(statusdata[0]));
      setFilteredData(filteredData)
      const formatTeamData = (team) => {
        const lead = Array.isArray(team?.lead) ? team?.lead : [team?.lead];
        const members = team?.members;
        const manager = team?.accountmanager;
        const combinedArray =
          manager !== ""
            ? [...lead, ...members, manager]
            : [...lead, ...members];
        // Remove duplicates by creating a Set and then converting it back to an array
        const uniqueArray = [...new Set(combinedArray)];

        team.members = uniqueArray;

        return team;
      };
      
      if (cookie?.role === "Admin") {
        if (statusdata.length > 0) {
          const firstItem = formatTeamData(statusdata[0]);

          setSelectedTeam(firstItem);
          setFirstItemOfTeam(firstItem);
          setSelectTeamName({
            value: firstItem?._id,
            label: firstItem?.teamName,
          });
          
          getUniversalCount(
            format(defaultStartDate, "dd/MM/yyyy"),
            format(defaultEndDate, "dd/MM/yyyy"),
            filteredData?.map((el)=>el._id),
            firstItem?._id
          );
        } else {
          setSelectTeamName({
            value: "",
            label: "",
          });
        }
        setAlluserTeams(statusdata);
      } else {
        const findTeamsForCandidate = (candidateId) => {
          return statusdata.filter(
            (team) =>{
            return  team.lead?.map((lead) => {
                return String(lead._id) === String(candidateId)
              }) ||
              team.members?.map((member) => {
                return String(member._id) === String(candidateId)
              }) ||
              String(team.accountmanager._id) === String(candidateId)
            }
          );
        };

        const teams = findTeamsForCandidate(userId?.trim());

        if (teams.length > 0) {
          const firstItem = formatTeamData(teams[0]);
          setSelectedTeam(firstItem);
          setFirstItemOfTeam(firstItem);
          setSelectTeamName({
            value: firstItem?._id,
            label: firstItem?.teamName,
          });
          getUniversalCount(
            format(defaultStartDate, "dd/MM/yyyy"),
            format(defaultEndDate, "dd/MM/yyyy"),
            cookie?.role !== "Recruiter"
              ? selectDashboard?.value !== "userdashboard"
                ? ""
                : cookie?.role == "Admin"
                  ? ""
                  : userId?.trim()
              : userId?.trim(),
            firstItem?._id
          );
          setAlluserTeams(teams);
        } else {
          setSelectTeamName({
            value: "",
            label: "",
          });
          setAlluserTeams([]);
        }
      }
    } catch (err) {
      toast.error(err.response.data.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    const tokenExists = cookie?.token?.length;
    const isNotUserDashboard = selectDashboard?.value !== "userdashboard";
    const isClientDashboard = selectDashboard?.value == "clientdashboard";
    const loginUser =
      cookie?.role !== "Recruiter"
        ? selectDashboard?.value !== "userdashboard"
          ? []
          : cookie?.role == "Admin"
            ? []
            : [userId?.trim()]
        : [userId?.trim()];

    setSearchOwnerName(loginUser);

    if (tokenExists && isNotUserDashboard && !isClientDashboard) {
      getAllUserTeams();
    } else if (isClientDashboard) {
      getUniqueClientNames();
      setSelectedTeam(null);
    } else {
      setSelectTeamName({
        value: "",
        label: "",
      });
      setSelectedTeam(null);
      
      candidate_ownerName &&
        cookie?.role &&
        getUniversalCount(
          format(defaultStartDate, "dd/MM/yyyy"),
          format(defaultEndDate, "dd/MM/yyyy"),
          cookie?.role !== "Recruiter"
            ? selectDashboard?.value !== "userdashboard"
              ? ""
              : cookie?.role == "Admin"
                ? ""
                : userId?.trim()
            : userId?.trim()
        );
    }
  }, [selectDashboard?.value, cookie?.role, candidate_ownerName]);

  useEffect(() => {
    if (cookie?.token?.length && candidate_ownerName) {
      getJobpostdata(page, rowsPerPage);
      getCandidatedata(page, rowsPerPage);
    } else {
      history.replace("/");
    }
  }, [cookie?.role, candidate_ownerName]);

  const predefinedRanges = [
    {
      label: "Today",
      value: [startOfDay(new Date()), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(addDays(new Date(), -1)),
        startOfDay(addDays(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "Last 7 Days",
      value: [startOfDay(addDays(new Date(), -7)), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Last 30 Days",
      value: [startOfDay(addDays(new Date(), -30)), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "This Week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Current Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
      placement: "left",
    },
    {
      label: "Previous Month",
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
      placement: "left",
    },
  ];

  const handleDateChange = (value) => {
    const formattedDates =
      value && value?.map((date) => format(date, "dd/MM/yyyy"));
    if (formattedDates) {
      setSearchStartDate(formattedDates[0]);
      setSearchEndDate(formattedDates[1]);
    }
  };

  const handleResetFilter = () => {
    setSearchStartDate(format(defaultStartDate, "dd/MM/yyyy"));
    setSearchEndDate(format(defaultEndDate, "dd/MM/yyyy"));
    setSearchOwnerName(
      cookie?.role !== "Recruiter"
        ? selectDashboard?.value !== "userdashboard"
          ? []
          : cookie?.role == "Admin"
            ? []
            : [userId?.trim()]
        : [userId?.trim()]
    );
    if (cookie.role == "Admin") {
      getJobpostdata(page, rowsPerPage, cookie.role == "Admin" && "");
      getCandidatedata(page, rowsPerPage, cookie.role == "Admin" && "");
    }
    if (
      selectDashboard.value == "userdashboard" ||
      selectDashboard.value == "teamdashboard"
    ) {
      getUniversalCount(
        format(defaultStartDate, "dd/MM/yyyy"),
        format(defaultEndDate, "dd/MM/yyyy"),
        cookie?.role !== "Recruiter"
          ? selectDashboard?.value !== "userdashboard"
            ? ""
            : cookie?.role == "Admin"
              ? ""
              : userId?.trim()
          : userId?.trim(),
        selectDashboard?.value !== "userdashboard"
          ? firstItemOfTeam?._id || ""
          : ""
      );
    }

    if (selectDashboard?.value == "teamdashboard") {
      setSelectedTeam(firstItemOfTeam);
      setSelectTeamName({
        value: firstItemOfTeam?._id,
        label: firstItemOfTeam?.teamName,
      });
    }
    if (selectDashboard?.value == "clientdashboard") {
      setSearchClientName(firstItemOfClient);
      getUniversalCount(
        format(defaultStartDate, "dd/MM/yyyy"),
        format(defaultEndDate, "dd/MM/yyyy"),
        "",
        "",
        firstItemOfClient?.value
      );
    }
  };

  const parseDate = (dateStr) => parse(dateStr, "dd/MM/yyyy", new Date());
  if (selectDashboard.value == "clientdashboard") {
    ownershipChatOption.xaxis = {
      ...ownershipChatOption.xaxis,
      categories: ["Jobs", "Submissions"],
    };
  }

  const chartState = {
    series: [
      {
        name: "Count",
        data:
          selectDashboard.value == "clientdashboard"
            ? [
                totalCounts?.jobpostCount || 0,
                totalCounts?.submissionCount || 0,
              ]
            : [
                totalCounts?.jobpostCount || 0,
                totalCounts?.candidateCount || 0,
                totalCounts?.submissionCount || 0,
              ],
      },
    ],
    options: ownershipChatOption,
  };

  const FunnelChartState = {
    series: [
      totalCounts?.submissionCount || 0,
      totalCounts?.rejectedCandidateCount || 0,
      totalCounts?.selectedCandidateCount || 0,
      totalCounts?.joinedCandidateCount || 0,
    ],
    options: FunnelChartOptions,
  };

  const ColumnChartsState = {
    series: [
      {
        name: "Count",
        data: sourceDataValues || [],
      },
    ],
    options: {
      ...ColumnChartsObject,
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        categories: sourceDataKeys || [],
        tickPlacement: "on",
      },
    },
  };

  const filterPortalUsersByTeam = (team) => {
    if(team){
      const lead = Array.isArray(team?.lead)
        ? team?.lead
        : [team?.lead];
      const accountManager = [team?.accountmanager];

      
      const combinedTeamMembers = [
        ...lead,
        ...team?.members?.flat(),
        ...accountManager,
      ];

      const modifiedCombinedTeamMembers = combinedTeamMembers.map((id) => {
        const result = userNames.find((user) => user?._id == id?._id);        
        return result?._id;
      });
      
      return userNames.filter((user) =>
        modifiedCombinedTeamMembers.includes(user._id)
      );
    }else{
      if (selectedTeam) {
        // Extract the lead and account manager
        const lead = Array.isArray(selectedTeam?.lead)
          ? selectedTeam?.lead
          : [selectedTeam?.lead];
        const accountManager = [selectedTeam?.accountmanager];
  
        // Combine lead, members, and account manager into a single array
        const combinedTeamMembers = [
          ...lead,
          ...selectedTeam?.members?.flat(),
          ...accountManager,
        ];
        // Filter portal users based on the unique team members        
  
        const modifiedCombinedTeamMembers = combinedTeamMembers.map((id) => {
          const result = userNames.find((user) => user?._id == id?._id);          
          return result?._id;
        });
        
        return userNames.filter((user) =>
          modifiedCombinedTeamMembers.includes(user._id)
        );
        
      }
    }
   
    return userNames;
  };

  const getUniqueUsers = (users) => {
    const uniqueUsersSet = new Set();
    return users?.filter((user) => {
      const fullName = `${user?.firstName?.trim()} ${user?.lastName?.trim()}`;
      if (uniqueUsersSet.has(fullName)) {
        return false;
      }
      uniqueUsersSet.add(fullName);
      return true;
    });
  };

  const [allClientNames, setAllClientNames] = useState([]);
  async function getUniqueClientNames() {
    try {
      const { data } = await axios.get(`${config?.apiUrl}/api/client/names`);
      setAllClientNames(
        data.clients?.map((res) => {
          return {
            value: res._id,
            label: res.accountName,
          };
        })
      );
      data.clients?.[0]._id &&
        getUniversalCount(
          format(defaultStartDate, "dd/MM/yyyy"),
          format(defaultEndDate, "dd/MM/yyyy"),
          "",
          "",
          data.clients?.[0]._id
        );
      setSearchClientName({
        value: data.clients?.[0]._id,
        label: data.clients?.[0].accountName,
      });
      setFirstItemOfClient({
        value: data.clients?.[0]._id,
        label: data.clients?.[0].accountName,
      });
    } catch (error) {
      setAllClientNames([]);
    }
  }

  function createQueryString(obj) {    
    const result =  Object.entries(obj)
      .filter(([_, value]) => {
        if (Array.isArray(value) && value.length === 0) return false;
        return value != null && value !== "";
      })
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
      return result
  }

  const params = {
    sd: searchStartDate,
    ed: searchEndDate,
    ownerNames:
      Array.isArray(searchOwnerName) && searchOwnerName.length
        ? searchOwnerName
        : selectDashboard?.value == "teamdashboard"
          // ? getUniqueUsers(filterPortalUsersByTeam()).map(
          //     (user) => `${user.firstName?.trim()} ${user.lastName?.trim()}`
          //   )
          ? getUniqueUsers(filterPortalUsersByTeam()).map(
            (user) => user?._id
          )
          : [],
    clientId:
      selectDashboard?.value == "clientdashboard"
        ? searchClientName?.value
        : "",
  };

  return (
    <Flex flexDirection="column" pt={"65px"}>
      <ToastContainer />
      <Box
        sx={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            flexWrap: "wrap",
          }}
        >
          <DateRangePicker
            format="dd/MM/yyyy"
            showOneCalendar
            ranges={predefinedRanges}
            onChange={handleDateChange}
            value={[parseDate(searchStartDate), parseDate(searchEndDate)]}
          />

          {(cookie?.role === "Admin" ||
            (["Account Manager", "Team Lead"].includes(cookie?.role) &&
              selectDashboard?.value !== "userdashboard")) && (
            <CheckPicker
              data={getUniqueUsers(filterPortalUsersByTeam()).map((user) => ({
                label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                value: user?._id,
              }))}
              style={{ width: 224 }}
              value={searchOwnerName}
              onChange={(e) => {
                setSearchOwnerName(e);
              }}
              placeholder="Select Users"
            />
          )}

          {["Account Manager", "Team Lead", "Admin"].includes(cookie?.role) &&
            selectDashboard?.value == "clientdashboard" && (
              <ReactSelect
                className="ReactSelectClass"
                classNamePrefix="ReactSelectClass"
                placeholder="Select Client Name"
                isClearable={false}
                name="selectTeamName"
                options={allClientNames}
                value={searchClientName}
                onChange={(selectedOption) => {
                  setSearchClientName(selectedOption);
                }}
              />
            )}

          {/* {["Account Manager", "Team Lead", "Admin"].includes(cookie?.role) &&
            selectDashboard?.value == "clientdashboard" &&
            allClientNames?.length && (
              <CheckPicker
                data={allClientNames?.map((res) => {
                  return {
                    label: `${res.accountName?.trim()}`,
                    value: `${res.accountName?.trim()} `,
                  };
                })}
                style={{ width: 224 }}
                value={searchClientName}
                onChange={(e) => {
                  console.log('e============',e);
                  setSearchClientName(e);
                }}
                placeholder="Select Contact Person"
              />
            )} */}

          {cookie?.role !== "Recruiter" &&
            selectDashboard?.value == "teamdashboard" && (
              <ReactSelect
                className="ReactSelectClass"
                classNamePrefix="ReactSelectClass"
                placeholder="Select Team"
                isSearchable={false}
                isClearable={false}
                name="selectTeamName"
                options={alluserTeams.map((user) => ({
                  value: user._id,
                  label: user.teamName,
                }))}
                value={selectTeamName}
                onChange={(selectedOption) => {
                  setSelectTeamName(selectedOption);
                  var filterSelectedItem = alluserTeams.find(
                    (res) => res._id == selectedOption.value
                  );
                  const lead = Array.isArray(filterSelectedItem.lead)
                    ? filterSelectedItem.lead
                    : [filterSelectedItem.lead];
                  const members = filterSelectedItem?.members;
                  const manager = filterSelectedItem.accountmanager;
                  const combinedArray =
                    manager !== ""
                      ? [...lead, ...members, manager]
                      : [...lead, ...members];

                  // Remove duplicates by creating a Set and then converting it back to an array
                  const uniqueArray = [...new Set(combinedArray)];
                  setFilteredData(uniqueArray)
                  filterSelectedItem.members = uniqueArray;
                  setSelectedTeam(filterSelectedItem);
                  setSearchOwnerName([]);
                }}
              />
            )}

          <Button
            sx={{ background: "", color: "#fff", outline: "1px solid #fff" }}
            _hover={{
              backgroundColor: "#fff",
              outlineColor: "var(--main-bg-color)",
              color: "var(--main-bg-color)",
            }}
            onClick={() => {
              if (selectDashboard.value == "clientdashboard") {
                getUniversalCount(
                  searchStartDate,
                  searchEndDate,
                  searchOwnerName,
                  "",
                  searchClientName?.value
                );
              } else if(selectDashboard.value == "teamdashboard"){
                getUniversalCount(
                  searchStartDate,
                  searchEndDate,
                  searchOwnerName.length>0?searchOwnerName: [...new Set(filteredData.map(el => el._id))],
                  selectTeamName?.value,
                  ""
                );
              }
                else {
                getUniversalCount(
                  searchStartDate,
                  searchEndDate,
                  searchOwnerName,
                  selectTeamName?.value,
                  ""
                );
              }

              if (selectDashboard.value == "userdashboard") {
                getJobpostdata(page, rowsPerPage);
                setSelectTeamName(null);
                getCandidatedata(page, rowsPerPage);
              }
            }}
          >
            Apply
          </Button>
          <Button
            sx={{
              backgroundColor: "#fff",
              color: "var(--main-bg-color)",
              outline: "var(--main-bg-color)",
            }}
            _hover={{
              backgroundColor: "transperant",
              outlineColor: "var(--main-bg-color)",
              color: "var(--main-bg-color)",
            }}
            onClick={() => handleResetFilter()}
          >
            Reset
          </Button>
        </Box>
        {cookie?.role !== "Recruiter" && (
          <ReactSelect
            className="ReactSelectClass"
            classNamePrefix="ReactSelectClass"
            placeholder="Select Dashboard"
            isSearchable={false}
            isClearable={false}
            name="selectDashboard"
            options={[
              { value: "userdashboard", label: "User Dashboard" },
              { value: "teamdashboard", label: "Team Dashboard" },
              { value: "clientdashboard", label: "Client Dashboard" },
            ]}
            value={selectDashboard}
            onChange={(selectedOption) => {
              setSelectDashboard(selectedOption);
            }}
          />
        )}
      </Box>
      <SimpleGrid
        columns={{
          sm: 1,
          md: 2,
          xl: selectDashboard?.value == "clientdashboard" ? 4 : 5,
        }}
        spacing="24px"
        mb="50px"
      >
        <CountCard
          label={"Total Jobs"}
          path={`/admin/jobpost?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.jobpostCount}
          icon={<WorkOutlineIcon fontSize="small" style={{ color: "white" }} />}
          text={"New Job Posted"}
          tooltipData={[
            "Active",
            "Deactive",
            "On Hold",
            "Cancelled",
            "Target Date Expired",
          ]}
        />
        {selectDashboard?.value !== "clientdashboard" && (
          <CountCard
            label={"Total Candidates"}
            path={`/admin/candidate?${createQueryString(params)}`}
            loading={countLoading}
            numberCount={totalCounts?.candidateCount}
            icon={<GroupIcon style={{ color: "white" }} />}
            text={"Candidates"}
          />
        )}
        <CountCard
          label={"Total Submissions"}
          path={`/admin/submission?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.submissionCount}
          icon={<DescriptionIcon style={{ color: "white" }} />}
          text={"Resume Received"}
          tooltipData={tooltipStatus?.submitedData}
        />

        <CountCard
          label={"Total Test Pending Candidates"}
          path={`/admin/submission?${createQueryString({ ...params, status: "Test - Pending" })}`}
          loading={countLoading}
          numberCount={totalCounts?.testPendingCount}
          icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
          text={"Test Pending Candidates"}
          tooltipData={["Test - Pending"]}
        />
        <CountCard
          label={"Total Test Cleared Candidates"}
          path={`/admin/interviewschedule?${createQueryString({ ...params, status: "Test Cleared" })}`}
          loading={countLoading}
          numberCount={totalCounts?.testClearedCount}
          icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
          text={"Test Cleared Candidates"}
          tooltipData={["Test Cleared"]}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 5 }} spacing="24px" mb="20px">
        <CountCard
          label={"Total Interview Schedule"}
          path={`/admin/interviewschedule?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.interviewScheduleCount}
          icon={<EventAvailableIcon style={{ color: "white" }} />}
          text={"Interview Schedule"}
          tooltipData={tooltipStatus?.scheduleData}
        />
        <CountCard
          label={"Total Interview Attained"}
          path={`/admin/interviewattained?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.interviewAttainedCount}
          icon={<WorkHistoryIcon style={{ color: "white" }} />}
          text={"Interview Attained"}
          tooltipData={tooltipStatus?.attainedData}
        />
        <CountCard
          label={"Total Selected Candidate"}
          path={`/admin/selected?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.selectedCandidateCount}
          icon={<HowToRegIcon style={{ color: "white" }} />}
          text={"Selected Candidate"}
          tooltipData={tooltipStatus?.selectedData}
        />
        <CountCard
          label={"Total Joined Candidates"}
          path={`/admin/joined?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.joinedCandidateCount}
          icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
          text={"Joined Candidates"}
          tooltipData={tooltipStatus?.joinedData}
        />
        <CountCard
          label={"Total Rejected Candidate"}
          path={`/admin/rejected?${createQueryString(params)}`}
          loading={countLoading}
          numberCount={totalCounts?.rejectedCandidateCount}
          icon={
            <Icon
              as={RiUserForbidFill}
              boxSize={5}
              style={{ color: "white" }}
            />
          }
          text={"Rejected Candidate"}
          tooltipData={tooltipStatus?.rejectedData}
        />
      </SimpleGrid>

      {teamMemberDatas.length !== 0 &&
        selectDashboard.value == "teamdashboard" && (
          <MemberRecordsTable
            loading={countLoading}
            teamMemberDatas={teamMemberDatas}
            selectedTeamData={selectedTeamData}
            params={params}
            createQueryString={createQueryString}
          />
        )}

      {/* Top Submission User */}
      <Grid
        templateColumns={{ sm: "1fr", lg: "1fr" }}
        templateRows={{ lg: "repeat(3, auto)" }}
        gap="20px"
      >
        {cookie?.role !== "Recruiter" &&
          (cookie?.role == "Admin" ||
            selectDashboard.value !== "userdashboard") && (
            <TopSubmissionUsers
              loading={countLoading}
              topSubmissionUsersData={topSubmissionUsersData}
              selectedTeamData={selectedTeamData}
              params={params}
              createQueryString={createQueryString}
            />
          )}
      </Grid>

      <Box sx={{ marginBottom: "1rem" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px" mb="50px">
          <Card p="22px">
            <Text fontSize="lg" fontWeight="bold" className="title_color">
              Ownership
            </Text>
            {countLoading ? (
              <Box
                sx={{
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="var(--main-bg-color)"
                  size="xl"
                />
              </Box>
            ) : (
              <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="bar"
                height={250}
              />
            )}
          </Card>
          <Card p="22px">
            <Text fontSize="lg" fontWeight="bold" className="title_color">
              Candidate Submissions to Placements
            </Text>
            {countLoading ? (
              <Box
                sx={{
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="var(--main-bg-color)"
                  size="xl"
                />
              </Box>
            ) : (
              <ReactApexChart
                options={FunnelChartState.options}
                series={FunnelChartState.series}
                type="pie"
                height={250}
              />
            )}
          </Card>
        </SimpleGrid>
        <Card p="22px">
          <Text fontSize="lg" fontWeight="bold" className="title_color">
            Candidates Sourced
          </Text>
          {countLoading ? (
            <Box
              sx={{
                height: "450px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="var(--main-bg-color)"
                size="xl"
              />
            </Box>
          ) : (
            <ReactApexChart
              options={ColumnChartsState.options}
              series={ColumnChartsState.series}
              type="bar"
              height={450}
            />
          )}
        </Card>
      </Box>

      {/* Candidate and Job Table section */}
      {selectDashboard?.value == "userdashboard" && (
        <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
          templateRows={{ lg: "repeat(2, auto)" }}
          gap="20px"
        >
          <Card p="0px">
            <Flex direction="column">
              <Flex align="center" justify="space-between" p="22px">
                <Text fontSize="lg" fontWeight="bold" className="title_color">
                  New Jobs
                </Text>
                <Link to="/admin/jobpost">
                  <Button variant="primary" maxH="30px" className="bg_color">
                    SEE ALL
                  </Button>
                </Link>
              </Flex>
              <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                <Table>
                  <Thead>
                    <Tr bg={tableRowColor}>
                      <Th color="gray.400" borderColor={borderColor}>
                        Job Title
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        City
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        Client
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        Owner Name
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading ? (
                      <>
                        <Tr>
                          <Td colSpan={4}>
                            <Loadar />
                          </Td>
                        </Tr>
                      </>
                    ) : (
                      <>
                        {jobposts?.length == 0 ? (
                          <>
                            <Tr>
                              <Td colSpan={11}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={NotFound}
                                    alt="NotFound"
                                    style={{ width: "16rem" }}
                                  />
                                  <p>No Records Found! Please try again....</p>
                                </div>
                              </Td>
                            </Tr>
                          </>
                        ) : (
                          <>
                            {jobposts &&
                              jobposts?.map((value, index, arr) => {
                                return (
                                  <Tr key={index}>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      fontWeight="bold"
                                      borderColor={borderColor}
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                    >
                                      <Link
                                        to={`/details/jobpostview/${value?._id}`}
                                      >
                                        {!value?.jobtitle
                                          ? "N/A"
                                          : value?.jobtitle}
                                      </Link>
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      {!value?.city ? "N/A" : value?.city}
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      <Link
                                        to={`/details/accountview/${value?.clientId?._id}`}
                                      >
                                        {!value?.clientId?.accountName
                                          ? "N/A"
                                          : value?.clientId?.accountName}
                                      </Link>
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      {!value?.job_owener_data
                                        ? "N/A"
                                        : `${value?.job_owener_data?.firstName} ${value?.job_owener_data?.lastName}`  }
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </Card>
          <Card p="0px">
            <Flex direction="column">
              <Flex align="center" justify="space-between" p="22px">
                <Text fontSize="lg" fontWeight="bold" className="title_color">
                  New Candidates
                </Text>
                <Link to="/admin/candidate">
                  <Button variant="primary" maxH="30px" className="bg_color">
                    SEE ALL
                  </Button>
                </Link>
              </Flex>
              <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                <Table>
                  <Thead>
                    <Tr bg={tableRowColor}>
                      <Th color="gray.400" borderColor={borderColor}>
                        Full Name
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        Status
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        Owner Name
                      </Th>
                      <Th
                        color="gray.400"
                        borderColor={borderColor}
                        textAlign={"center"}
                      >
                        Created Date
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {candidateLoading ? (
                      <>
                        <Tr>
                          <Td colSpan={4}>
                            <Loadar />
                          </Td>
                        </Tr>
                      </>
                    ) : (
                      <>
                        {candidates?.length == 0 ? (
                          <>
                            <Tr>
                              <Td colSpan={11}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={NotFound}
                                    alt="NotFound"
                                    style={{ width: "16rem" }}
                                  />
                                  <p>No Records Found! Please try again....</p>
                                </div>
                              </Td>
                            </Tr>
                          </>
                        ) : (
                          <>
                            {candidates?.length &&
                              candidates?.map((value, index, arr) => {
                                return (
                                  <Tr key={index}>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      fontWeight="bold"
                                      borderColor={borderColor}
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                    >
                                      <Link
                                        to={`/details/candidateview/${value._id}`}
                                      >
                                        {`${value?.firstName} ${value?.lastName}` ==
                                          "" || undefined
                                          ? "N/A"
                                          : `${value?.firstName} ${value?.lastName}`}
                                      </Link>
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      {!value?.status ? "N/A" : value?.status}
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      {!value?.candidate_ownerName
                                        ? "N/A"
                                        : `${value?.candidate_ownerName?.firstName} ${value?.candidate_ownerName?.lastName}`}
                                    </Td>
                                    <Td
                                      color={textTableColor}
                                      fontSize="sm"
                                      border={
                                        index === arr.length - 1 ? "none" : null
                                      }
                                      borderColor={borderColor}
                                      textAlign={"center"}
                                    >
                                      {!value?.created_at
                                        ? "N/A"
                                        : typeof value?.created_at === "string"
                                          ? value?.created_at
                                          : new Date(
                                              value?.created_at
                                            ).toLocaleDateString("en-GB")}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </Card>
        </Grid>
      )}
    </Flex>
  );
}
