import axios from "axios";
import { toast } from "react-toastify";
import config from "./config";
import moment from "moment";

const handleUpdateStatusChangeMailSendToCandidate = async (
  submission,
  responseData,
  oldStatus,
  changeStatus
) => {
  try {
    const job = submission?.jobId;
    const candidate = submission?.candidateId;
    const client = submission?.clientId;
    const response = await axios.post(
      `${config?.apiUrl}/api/send_email_to_Candidate_of_status_update`,
      {
        job: job,
        candidate: candidate,
        client: client,
        oldStatus: oldStatus,
        responseData: responseData,
        contactPerson: changeStatus && changeStatus,
      }
    );

    if (response.data && response.data.msg) {
      toast.success(response.data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Invalid response format", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.error("Error sending status update email:", error);
    toast.error("Error sending status update email", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
const handleCandidateShortSummarySubmit = async (note) => {
  await axios
    .post(`${config?.apiUrl}/api/Set_Candidate_Note`, {
      candidateId: note.candidateId,
      candidateNote: note.candidateNote,
      noteSubmitter: note?.created_by || `Created by system`,
      created_at: new Date().getTime(),
    })
    .then((result) => { return result.json()})
    .catch((err) => {
      toast.error(err || "Please check details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};
const handleSaveNoteShortSummaryEdit = async (note) => {
  const id = note?.id;
  const { candidateNote } = note;
  const url = `${config?.apiUrl}/api/update_Candidate_Note/${id}`;
  const Credentials = { candidateNote };
  await axios
    .put(url, Credentials)
    .then((result) => {})
    .catch((err) => {
      toast.error(err.response?.data?.msg || "Please check details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

const getJobNotes = async (id, setGetNotes, setNotesLoading) => {
  try {
    setNotesLoading(true);
    if (id) {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_Job_Note/${id}`
      );
      const notedata = response.data.data;
      setGetNotes(notedata);
      setNotesLoading(false);
    } else {
      setNotesLoading(false);
    }
  } catch (error) {
    setNotesLoading(false);
    toast.error(
      error?.message ||
        "Something happened wrong, please try again after sometime.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }
};
const getContactNotes = async (id, setGetNotes, setNotesLoading) => {
  try {
    setNotesLoading(true);
    if (id) {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_Contact_Note/${id}`
      );
      const notedata = response.data.data;
      setGetNotes(notedata);
      setNotesLoading(false);
    } else {
      setNotesLoading(false);
    }
  } catch (error) {
    setNotesLoading(false);
    toast.error(
      error?.message ||
        "Something happened wrong, please try again after sometime.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }
};

const handleGenerateJD = async (e, formik, setJdLoading) => {
  setJdLoading(true);
  e.preventDefault();
  const jobtitle = formik.values.jobtitle;
  const jobLocation = formik.values.city;
  const experience = formik.values.experience;
  const numberofPosition = formik.values.numberofPosition;
  const remotestatus = formik.values.remotestatus;
  const skillValue = formik.values.skill;

  try {
    if (!jobtitle) {
      toast.error("Job title is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!jobLocation) {
      toast.error("City is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!experience) {
      toast.error("Experience is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!remotestatus) {
      toast.error("Remote status is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!numberofPosition) {
      toast.error("Number of positions is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!skillValue) {
      toast.error("Skill is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const response = await axios.post(
        `${config.apiUrl}/generate-job-description`,
        {
          skill: skillValue,
          remotestatus: remotestatus,
          experience: experience,
          jobtitle: jobtitle,
          numberofPosition: numberofPosition,
          jobLocation: jobLocation,
        }
      );

      if (response.status !== 200) {
        throw new Error(
          `Failed to generate job description. Status: ${response.status}`
        );
      }

      const data = response.data;
      const generatedJD = data.jobdescription;
      formik.setFieldValue("jobdescription", generatedJD);
    }
  } catch (error) {
    console.error("Error:", error.message);
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    setJdLoading(false);
  }
};

//userActivity

const userActivity = async (
  candidate_ownerName,
  userId,
  component,
  activityDo,
  activityOn,
  userRole
) => {
  try {
    const created_at = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    const addActivityResponse = await axios.post(
      `${config.apiUrl}/addActivities`,
      {
        userName: candidate_ownerName,
        userId: userId,
        component: component,
        activity: activityDo,
        activityOn: activityOn,
        userRole: userRole,
        created_at: created_at,
      }
    );
  } catch (error) {
    console.error("Error:", error);
  }
};

const formatHeader = (key) => {
  const replacements = {
    email: "Email",
    phone: "Phone",
    designation: "Designation",
    experiance: "Experiance",
    skill: "skill",
    owner_name: "Owner name",
    city: "City",
    state: "State",
    status: "Status",
    candidatestatus: "Candidate status",
    created_at: "Created On",
    degree: "Degree",
    companyname: "Company name",
    Companydesignation: "Company designation",
    aadharcardnumber: "Aadhar card number",
    pancardnumber: "Pan card number",
    gender: "Gender",
    pincode: "Pincode",
    address: "Address",
    DOB: "DOB",
    source: "source",
    country: "country",
    sendMail: "Send mail",
    currentCandidateCTCType: "Current candidate CTC type",
    currentCandidateCTC: "current candidate CTC",
    expectedCandidateCTCType: "Expected candidate CTC type",
    expectedCandidateCTC: "Expected candidate CTC",
    lastName: "Last name",
    firstName: "First name",
    jobtitle: "Job Title",
    client: "Client",
    clientcontactname: "Client Contact Name",
    noticePeriod: "Notice period",
    submission_at: "Submission at",
    clientname: "client name",
    contactperson: "Contact person",
    accountmanager: "Account manager",
    recruiter: "Recruiter",
    teamleader: "Team leader",
    skill: "Skill",
    website: "Website",
    industry: "Industry",
    accountOwner: "Account owner",
    accountName: "accountName",
    experience: "Experience",
    workExperience: "Company Name",
    educationDetails: "Education",
    fullName: "Full name",
    interviewScheduleDate: "Interview schedule date",
    interviewScheduleTime: "Interview schedule time",
    interviewScheduleLocation: "Interview schedule location",
    interviewScheduleLink: "Interview schedule link",
    joinDate: "Joining Date",
    submissionBy: "Submission By",
    actualCTC: "Actual CTC",
    expectedJoinDate: "Expected Join Date",
    offerCTC: "Offer CTC",
    created_by: "Created By",
    modified_by: "Modified By",
    updated_at: "Updated At",
    updatedBy: "Updated By",
    role: "Role",
    industry: "Industry",
    interviewRescheduleDate : "Interview Reschedule Date",
    testRescheduleDate : "Test Reschedule Date",
    backoutDate : "Backout Date"
  };

  return replacements[key];
  // replacements[key.toLowerCase()] ||
  // key.charAt(0).toUpperCase() + key.slice(1)
};

const handleCheckStatusText = (candidatename, resData, submission, jobData, statusData) => {
   console.log(submission,'lllxjkxjs')
  let normalActivityStatus = "";
  const statusDetails = resData?.status;

  if (submission?.isStatusChange && resData?.status === "Pending") {
    normalActivityStatus = `Application Status: Pending | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName}`;
} else if (submission?.isStatusChange && resData?.status === "Internal-Submitted") {
    normalActivityStatus = `Application Status: Internal-Submitted | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Client - Submitted") {
    normalActivityStatus = `Application Status: Client - Submitted | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Client - Shortlisted") {
    normalActivityStatus = `Application Status: Client - Shortlisted | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Client - Duplicate") {
    normalActivityStatus = `Application Status: Client - Duplicate | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "Client - Rejected") {
    normalActivityStatus = `Application Status: Client - Rejected | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "Pipeline") {
    normalActivityStatus = `Application Status: Pipeline | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Test - Reschedule") {
    normalActivityStatus = `Application Status: Test - Reschedule | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Test - Reschedule Date: ${formatDate(resData?.testRescheduleDate)}`;
} else if (submission?.isStatusChange && resData?.status === "Rejected") {
    normalActivityStatus = `Application Status: Rejected | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "Internal - Rejected By TL") {
    normalActivityStatus = `Application Status: Internal - Rejected By TL | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "Not Interested") {
    normalActivityStatus = `Application Status: Not Interested | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "First round done") {
    normalActivityStatus = `Application Status: First round done | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "On hold") {
    normalActivityStatus = `Application Status: On hold | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData.message}`;
} else if (submission?.isStatusChange && resData?.status === "Test - Pending") {
    normalActivityStatus = `Application Status: Test - Pending | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Test Cleared") {
    normalActivityStatus = `Application Status: Test Cleared | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Test - Not Cleared") {
    normalActivityStatus = `Application Status: Test - Not Cleared | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Interview Scheduled") {
    normalActivityStatus = `Application Status: Interview Scheduled | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} | Interview - Reschedule   Time: ${resData?.interviewScheduleTime}, Date: ${formatDate(resData?.interviewScheduleDate)}, Location: ${resData?.interviewScheduleLocation}, Link: ${resData?.interviewScheduleLink}`;
} else if (submission?.isStatusChange && resData?.status === "Interview - Reschedule") {
    normalActivityStatus = `Application Status: Interview - Reschedule | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Interview - Reschedule Date: ${formatDate(resData?.interviewRescheduleDate)}`;
} else if (submission?.isStatusChange && resData?.status === "Interview Complete") {
    normalActivityStatus = `Application Status: Interview Complete | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Time: ${resData?.interviewScheduleTime}, Date: ${formatDate(resData?.interviewScheduleDate)}, Location: ${resData?.interviewScheduleLocation}, Link: ${resData?.interviewScheduleLink}`;
} else if (submission?.isStatusChange && resData?.status === "Interview - Rejected") {
    normalActivityStatus = `Application Status: Interview - Rejected | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Interview feedback Awaiting") {
    normalActivityStatus = `Application Status: Interview feedback Awaiting | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Second round done") {
    normalActivityStatus = `Application Status: Second round done | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Selected") {
    normalActivityStatus = `Application Status: Selected | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Expected Joining Date: ${formatDate(resData?.expectedJoinDate)}, Offer CTC: ${resData?.offerCTC}`;
} else if (submission?.isStatusChange && resData?.status === "Selected - Document Pending") {
    normalActivityStatus = `Application Status: Selected - Document Pending | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "Offered - Joining Pending") {
    normalActivityStatus = `Application Status: Offered - Joining Pending | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Joining date: ${formatDate(resData?.expectedJoinDate)}`;
} else if (submission?.isStatusChange && resData?.status === "Joined") {
    normalActivityStatus = `Application Status: Joined | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Joining Date: ${resData?.joinDate}, Offer CTC: ${resData?.actualCTC}`;
} else if (submission?.isStatusChange && resData?.status === "Position on Hold") {
    normalActivityStatus = `Application Status: Position on Hold | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} `;
} else if (submission?.isStatusChange && resData?.status === "For Future Position") {
    normalActivityStatus = `Application Status: For Future Position | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  ${resData?.message}`;
} else if (submission?.isStatusChange && resData?.status === "Backout") {
    normalActivityStatus = `Application Status: Backout | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName} |  Backout date: ${formatDate(resData?.backoutDate)}, ${resData?.message}`;
} else {
    normalActivityStatus = `Application submission has been updated | Job Title: ${jobData?.jobtitle} | Client Name: ${jobData?.clientId?.accountName}`;
}
  

  return normalActivityStatus;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB').format(date); 
};
const formatToDateISO = (inputDate, formatedate) => {
  const possibleFormats = [
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",
    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",
  ];

  const date = moment(inputDate, possibleFormats, true);

  if (!date.isValid()) {
    return "Invalid date";
  }

  return date.format(formatedate);
};

const getAllCities = async () => {
  try {
    const cities = await axios.get(`${config?.apiUrl}/api/cities`);
    return cities?.data?.data;
  } catch (error) {
    toast.error(error?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const getAllStates = async () => {
  try {
    const states = await axios.get(`${config?.apiUrl}/api/states`);
    return states?.data?.data;
  } catch (error) {
    toast.error(error?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const DEFAULT_CANDIDATE_STATUS = 'Pending'

const getOptionById = (id,userNames) => {
  const response = userNames?.find(option => option._id === id)
  return {
   value:response?._id,
   label:`${response?.firstName} ${response?.lastName}`
  }
 };


 function generateQueryParams(params) {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      if (Array.isArray(value)) {
        queryParams.append(key, value.join(","));
      } else {
        queryParams.append(key, value);
      }
    }
  });
 return queryParams.toString();
}

const DEFAULT_PAGE = 0;
const DEFAULT_ROW_PER_PAGE = 10;


export {
  handleUpdateStatusChangeMailSendToCandidate,
  handleCandidateShortSummarySubmit,
  handleSaveNoteShortSummaryEdit,
  getJobNotes,
  handleGenerateJD,
  getContactNotes,
  userActivity,
  formatHeader,
  handleCheckStatusText,
  formatToDateISO,
  getAllCities,
  getAllStates,
  DEFAULT_CANDIDATE_STATUS,
  getOptionById,
  generateQueryParams,
  DEFAULT_ROW_PER_PAGE,
  DEFAULT_PAGE
};
