import { Box } from "@chakra-ui/react";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { Checkbox as RsuitCheckbox } from "rsuite";
import { CheckPicker, IconButton } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
function CheckFilter({
  data, // userName
  value, // accountOwner
  setValue, // setAccountOwner
  isFilter, //  isFilterAccountOwner
  setIsFilter, // setIsFilterAccountOwner
  handlePopOverClose, // handlePopOverClose
  setActiveField, //setActiveField
  field, // filedName
  filterData, // filterData
  placeholder, // placeholder
  fetchData, // api data
  page,
  rowsPerPage
}) {
  const [selectedValue, setSelectedValue] = React.useState(value);
   const renderMenu = (menu, data) => {
    if (data.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> loading...
        </p>
      );
    }
    return menu;
  };
  const toSetFilter  =()=>{
    setValue(selectedValue);
    setActiveField({
      name: field,
      value: selectedValue,
    });
    setIsFilter(true);
    if (page && rowsPerPage) {
      filterData(0, rowsPerPage, field, selectedValue);
    } else {
      filterData(selectedValue, field);
    }

  }
  return (
    <div>
      <Box sx={{ display: "flex", gap: 5 }}>
        <CheckPicker
          data={data}
          style={{ width: 224 }}
          renderMenu={(menu) => renderMenu(menu, data)}
          menuClassName="reactMultiSelectMenu"
          value={selectedValue}
          onChange={(selectedOption) => {
            setSelectedValue(selectedOption);
          }}
          onClean={() => {
            setValue([]);
            setSelectedValue([]);
            setActiveField({
              name: "",
              value: "",
            });
            if (isFilter) {
              setIsFilter(false);
              handlePopOverClose(false);
              if (page && rowsPerPage) {
                filterData(0, rowsPerPage, field, "");
              } else {
                filterData("", field);
              }
            }
          }}
          onOpen={fetchData ? fetchData : () => {}}
          renderExtraFooter={() => (
            <div
              style={{
                padding: "7px 2px 0px",
                borderTop: "1px solid #e5e5e5",
              }}
            >
              <RsuitCheckbox
                indeterminate={
                  selectedValue.length > 0 && selectedValue.length < data.length
                }
                checked={selectedValue.length === data.length}
                onChange={(_, checked) => {
                  console.log("YYYYYYYYYY", checked, data, selectedValue);
                  setSelectedValue(
                    checked ? data.map((user) => user?.value) : []
                  );
                }}
              >
                Check all
              </RsuitCheckbox>
            </div>
          )}
          placeholder={placeholder}
        />
        <IconButton
          color={"#fff"}
          background={"var(--main-bg-color)"}
          aria-label="Search date range"
          _hover={{
            background: "var(--main-bg-color)",
          }}
          size={"sm"}
          disabled={!selectedValue?.length}
          icon={<FiSearch />}
          onClick={toSetFilter}
        />
      </Box>
    </div>
  );
}

export default CheckFilter;